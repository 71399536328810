import { Divider, Typography } from "@mui/material";
import Link from "../components/Link";
import Section from "../components/Section";

const Profile = () => {
  const nuudayLink = <Link text="Nuuday" url="https://nuuday.dk" />;
  const eduLink = <Link text="Master's in Software Engineering (cand.polyt)" url="http://www.sdu.dk/en/Uddannelse/Kandidat/SoftwareEngineering" />;
  const sduLink = <Link text="University of Southern Denmark" url="http://www.sdu.dk/en" />;
  const odenseLink = <Link text="Odense" url="https://www.google.dk/maps/place/Odense" />;
  const vibyLink = <Link text="Viby J" url="https://www.google.dk/maps/place/8260+Viby" />;
  const d3Link = <Link text="Diablo 3" url="https://eu.battle.net/d3/en/" />;
  const poeLink = <Link text="Path of Exile" url="https://www.pathofexile.com/" />;

  const linkedInLink = <Link text="LinkedIn" url="https://www.linkedin.com/in/thomashrasmussen" />;
  const emailLink = <Link text="send an e-mail" url="mailto:web@thomasheine.dk" />;

  return (
    <Section title="Thomas Heine Rasmussen" subtitle={<>Software Engineer at {nuudayLink}</>}>
      <Typography variant="body2" paragraph>
        In the summer of 2017 I completed my {eduLink} at {sduLink}.
      </Typography>
      <Typography variant="body2" paragraph>
        I have lived on Funen my whole life and in {odenseLink} for more than 10 years. After graduating I needed a change of scenery, so I found an apartment in {vibyLink} (near Aarhus). I am currently working at {nuudayLink} as a software engineer, where I am part of the Tech Transformation Tribe.
      </Typography>
      <Typography variant="body2" paragraph>
        In my spare time I like to work on my own small software projects while learning new technologies and programming languages. I also enjoy playing games and I spend way too much time playing {d3Link} and {poeLink}.
      </Typography>
      <Typography variant="h6" component="h3">
        Contact
      </Typography>
      <Divider style={{ marginBottom: "8px" }} />
      <Typography variant="body2" paragraph>
        If you need to get in touch with me, you can contact me on {linkedInLink} or {emailLink}.
      </Typography>
    </Section>
  );
};

export default Profile;
