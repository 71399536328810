import { createTheme, PaletteMode, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";

export const CreateAppTheme = (mode: PaletteMode): Theme => {
  return createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#378ad3",
      },
      secondary: {
        main: "#378ad3",
      },
    },
  });
};

export function useThemeChanger() {
  const themeLocalModeKey = "th-themeSelectedMode";

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<PaletteMode>(() => {
    const storedThemeMode = localStorage.getItem(themeLocalModeKey);
    if (storedThemeMode !== null) {
      return storedThemeMode === "dark" ? "dark" : "light";
    }
    return prefersDarkMode ? "dark" : "light";
  });
  const [theme, setTheme] = useState<Theme>(() => CreateAppTheme(mode));
  const isDarkMode = mode === "dark";

  const changeMode = () => {
    const newMode: PaletteMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    setTheme(CreateAppTheme(newMode));
    if (newMode === "dark") {
      localStorage.setItem(themeLocalModeKey, newMode);
    } else {
      localStorage.removeItem(themeLocalModeKey);
    }
  };

  return [isDarkMode, theme, changeMode] as const;
}
