import { Paper } from "@mui/material";
import { ReactNode } from "react";
import Title from "./Title";

interface Props {
  children: ReactNode;
  title?: string;
  subtitle?: string | ReactNode;
}

const Section = (props: Props) => {
  const { children, title, subtitle } = props;
  return (
    <Paper square style={{ padding: "8px 16px", margin: "16px 0" }}>
      {title && <Title title={title} subtitle={subtitle} />}
      {children}
    </Paper>
  );
};

export default Section;
