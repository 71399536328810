import { Divider, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  title: string;
  subtitle?: string | ReactNode;
  variant?: "default" | "project";
}

const Title = (props: Props) => {
  const { title, subtitle, variant } = props;
  const isProject = variant === "project";

  return (
    <>
      <Typography variant={isProject ? "h6" : "h5"} component={isProject ? "h3" : "h2"}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      )}
      <Divider style={{ marginBottom: "8px" }} />
    </>
  );
};

export default Title;
