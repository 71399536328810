import Section from "../components/Section";
import { Typography } from "@mui/material";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Section title={""}>
      <Typography align="center" variant="caption" color="textSecondary" component="p">
        &copy;{year}&nbsp;Thomas&nbsp;Heine&nbsp;Rasmussen | Last&nbsp;updated:&nbsp;May&nbsp;8,&nbsp;2021
      </Typography>
    </Section>
  );
};

export default Footer;
