import { Button, ButtonGroup, Typography } from "@mui/material";
import BaseProject from "./BaseProject";

const Minesweeper = () => {
  return (
    <BaseProject title="Minesweeper Multiplayer" subtitle="A browser game">
      <Typography variant="body2" paragraph>
        A multiplayer version of the classic game Minesweeper.
      </Typography>
      <ul style={{ marginTop: 0, paddingLeft: "2em", flexGrow: 1 }}>
        <li>
          <Typography variant="body2">Take on your friend in a head to head game or play up to 6 people on the same game.</Typography>
        </li>
        <li>
          <Typography variant="body2">Different game rules to make gameplay more interesting.</Typography>
        </li>
        <li>
          <Typography variant="body2"> Create a profile and play on different devices.</Typography>
        </li>
      </ul>
      <div style={{ textAlign: "center" }}>
        <ButtonGroup variant="contained" color="primary" disableElevation>
          <Button href="https://minesweeper-multiplayer.dk/">Play now</Button>
          <Button href="https://stats.minesweeper-multiplayer.dk/">View statistics</Button>
        </ButtonGroup>
      </div>
    </BaseProject>
  );
};

export default Minesweeper;
