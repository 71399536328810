import { AppBar, Container, FormControlLabel, FormGroup, Switch, Toolbar, Typography } from "@mui/material";

const TopBar = ({ isDarkTheme, toggleTheme }: { isDarkTheme: boolean; toggleTheme: () => void }) => {
  return (
    <AppBar position="static" color="default">
      <Container>
        <Toolbar variant="dense" style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="h1">
            ThomasHeine.dk
          </Typography>
          <FormGroup>
            <FormControlLabel control={<Switch checked={isDarkTheme} onChange={toggleTheme} />} label="Dark&nbsp;theme" />
          </FormGroup>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
