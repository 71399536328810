import Section from "../components/Section";
import { Grid, Typography } from "@mui/material";
import THPyramidSolitaire from "../projects/THPyramidSolitaire";
import Minesweeper from "../projects/Minesweeper";
import WhistScore from "../projects/WhistScore";

const MyProjects = () => {
  return (
    <>
      <Section title="My projects">
        <Typography variant="body2" paragraph>
          On rare occasions my personal projects reach a state where I feel comfortable enough to show it for the entire world to see.
        </Typography>
      </Section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <THPyramidSolitaire />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Minesweeper />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <WhistScore />
        </Grid>
      </Grid>
    </>
  );
};

export default MyProjects;
