import { Link as MatLink } from "@mui/material";

const Link = ({ text, url }: { text: string; url: string }) => {
  return (
    <MatLink href={url} rel="noopener noreferrer">
      {text}
    </MatLink>
  );
};

export default Link;
