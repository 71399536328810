import TopBar from "./sections/TopBar";
import Profile from "./sections/Profile";
import { Container, CssBaseline } from "@mui/material";
import MyProjects from "./sections/MyProjects";
import Footer from "./sections/Footer";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useThemeChanger } from "./components/useChangeThemeHook";

const App = () => {
  const [isDarkMode, theme, changeMode] = useThemeChanger();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopBar isDarkTheme={isDarkMode} toggleTheme={changeMode} />
        <Container>
          <Profile />
          <MyProjects />
          <Footer />
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
