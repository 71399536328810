import { Button, Typography } from "@mui/material";
import BaseProject from "./BaseProject";

const THPyramidSolitaire = () => {
  return (
    <BaseProject title="TH Pyramid Solitaire" subtitle="A Windows Phone game">
      <Typography variant="body2" paragraph>
        A free version of the classic game Pyramid Solitaire.
      </Typography>
      <ul style={{ marginTop: 0, paddingLeft: "2em", flexGrow: 1 }}>
        <li>
          <Typography variant="body2">Local highscore</Typography>
        </li>
        <li>
          <Typography variant="body2">Unlimited undo moves</Typography>
        </li>
        <li>
          <Typography variant="body2">Auto-saves the game</Typography>
        </li>
        <li>
          <Typography variant="body2">Notification when out of moves</Typography>
        </li>
      </ul>
      <div style={{ textAlign: "center" }}>
        <Button href="https://www.microsoft.com/store/apps/9NBLGGH0CC82" variant="contained" color="primary" disableElevation>
          Open in Windows Store
        </Button>
      </div>
    </BaseProject>
  );
};

export default THPyramidSolitaire;
