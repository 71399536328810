import { Button, Typography } from "@mui/material";
import BaseProject from "./BaseProject";

const WhistScore = () => {
  return (
    <BaseProject title="Whist Score" subtitle="A Web App">
      <Typography variant="body2" paragraph>
        Keep track of the score while playing the card game Whist.
      </Typography>
      <ul style={{ marginTop: 0, paddingLeft: "2em", flexGrow: 1 }}>
        <li>
          <Typography variant="body2">All participants can add the score after each round</Typography>
        </li>
        <li>
          <Typography variant="body2">All participants get live updates when a new round has been added</Typography>
        </li>
        <li>
          <Typography variant="body2">Simple statistics to see how each participant is doing</Typography>
        </li>
      </ul>
      <div style={{ textAlign: "center" }}>
        <Button href="https://whist-score.dk" variant="contained" color="primary" disableElevation>
          {" "}
          Go to website{" "}
        </Button>
      </div>
    </BaseProject>
  );
};

export default WhistScore;
