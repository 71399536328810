import { ReactNode } from "react";
import { Paper } from "@mui/material";
import Title from "../components/Title";

interface Props {
  children: ReactNode;
  title: string;
  subtitle: string;
}

const BaseProject = (props: Props) => {
  const { children, title, subtitle } = props;
  return (
    <Paper square style={{ padding: "8px 16px", height: "100%", display: "flex", flexDirection: "column" }}>
      <Title title={title} subtitle={subtitle} variant="project" />
      {children}
    </Paper>
  );
};

export default BaseProject;
